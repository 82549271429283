<div *ngIf="loaded===false">
  <app-loading></app-loading>
</div>
<div *ngIf="loaded===true && texto && (!textoStc || textoStc && textoStc === '')" class="jetsmart-modal">
  <div class="jetsmart-modal-content">
    <div class="modal-header">
      <img class="icon-modal" src="https://{{baseUrl}}/assets/exclamation.gif" alt>
      <h3 class="mt-1 d-inline text-white font-lato-black">{{cmsModalSection.modal_title}}</h3>
      <span class="close"><a (click)="onModalClose()">x</a></span>
    </div>
    <div class="modal-body">
      <!--<p class="static-modal-text">{{textToShow}}</p>-->
      <p class="static-modal-text" [innerHTML]="textToShow">{{textToShow}}</p>

      <div class="modal-button-container">
        <div class="container">
          <div class="row justify-content-center">
            <div *ngIf="texto !== 'refund_aa_fligth_change_modal_text'" class="col-auto">
              <button (click)="onModalClose()" class="btn btn-secondary">{{cmsModalSection.modal_button}}</button>
            </div>
            <div *ngIf="texto == 'refund_aa_fligth_change_modal_text'" class="col-auto">
              <button (click)="onModalCancelAACHG()" class="btn btn-primary me-2">Cancelar</button>
              <button (click)="onModalAcceptAACHG()" class="btn btn-secondary">Aceptar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div *ngIf="loaded===true && textoStc && textoStc !== ''">-->
  <!--<app-full-page class='full-page' [textoStc]='textoStc' ></app-full-page>-->
<!--</div>-->

